/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../config/colors'
import { Span } from './Typography'

interface IPrimaryBtn {
  title?: string
  onClick?: () => void
  isSubmit?: boolean
  isLoading?: boolean
  width?: string
  icon?: JSX.Element
  disabled?: boolean
}
function Primary({
  title,
  onClick,
  isSubmit,
  isLoading = false,
  width = '100%',
  icon,
  disabled
}: IPrimaryBtn) {
  return (
        <Button
          style={{ width }}
          type={isSubmit ? 'submit' : 'button'}
          onClick={onClick}
          disabled={disabled}
        >
          <span>{icon}</span>
          <Span color='white' center>{title}</Span>
        </Button>
  )
}

const Button = styled.button`
  width: 100%;
  background: ${({ disabled }) => (disabled ? COLORS.disabled : COLORS.black)};
  border-radius: 6px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  align-items: center;
  text-align: center;
  letter-spacing: 0.75px;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: center;
  @media (max-width: 650px) {
    font-size: 12px;
    height: 7vh !important;
  }
  @media (max-width: 1024px) {
    height: 4vh;
  }
`

export default Primary
