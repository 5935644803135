import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from './Routes';
import './App.css'
import './config/customStyles.css'
import 'antd/dist/antd.css'
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga4";

ReactGA.initialize("G-DT8NSB4QLN");

const App = () => (
  <BrowserRouter>
  <ToastContainer />
    <Routes />
  </BrowserRouter>
);

export default App;
