import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config/colors';

interface ITextInput {
  type?: React.HTMLInputTypeAttribute;
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  readonly?: boolean;
  title?: string;
  ref?: React.Ref<HTMLInputElement>
}

export const TextInputForwardRef = React.forwardRef<HTMLInputElement, ITextInput>((props, ref) => {
  return <Input ref={ref} {...props} marginBottom={'0px'} />
});

export default function TextInput({
  type,
  name,
  placeholder,
  required,
  maxLength,
  minLength,
  onChange,
  value,
  readonly,
  title,
  ref,
  ...rest
}: ITextInput) {
  return (
    <Input
      ref={ref}
      type={type}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
      value={value}
      readOnly={readonly}
      title={title}
      {...rest}
    />
  );
}

const Input = styled.input<{
  marginBottom?: string
}>`
width: 100%;
padding: 10px;
margin-bottom: ${({ marginBottom }) => marginBottom ?? '20px'};
line-height: 1.8;
font-size: 12px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid ${COLORS.light};
border-radius: 8px;
opacity: 1;

  ::placeholder {
    color: #80808080 !important
  }

  &[type=date]:invalid::-webkit-datetime-edit {
    color: #80808080 !important
  }
`;