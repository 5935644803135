import axios from 'axios'


export const API = axios.create({
  baseURL: 'https://savaapi.azurewebsites.net/api'
})

export const LocalAPI = axios.create({
  baseURL: 'https://localhost:5001/api'
})

export const FacebookAPI = axios.create({
  baseURL: 'https://graph.facebook.com/v2.12'
})