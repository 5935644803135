import { API } from '../config/axios'

/** Class representing an organization. */
class WaitList {

  async getUsers () {
    try {
      const req = await API.get(`SvoOrgWaitlist`)

      if (req.data.length < 1) return { isSuccess: false }

      return { isSuccess: true, data: req.data }
    } catch (error) {
      return { isSuccess: false }
    }
  }
}

export default new WaitList()
