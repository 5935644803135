import Main from './landing'
import ProductExpense from './product-expense'
import AccountIntegration from './account-integration'
import Cards from './cards'
import Privacy from './privacy'
import WaitList from './waitlist'
import Notice from './notice'
import Terms from './terms'
import BusinessContinuityDisclosure from './business-continuity-disclosure'
import AddWaitList from './add-waitlist'
import ConfirmEmail from './confirm-email'

const Pages = [
  {
    path: '/',
    exact: true,
    component: AddWaitList
  },
  // {
  //   path: '/expense',
  //   exact: true,
  //   component: ProductExpense
  // },
  // {
  //   path: '/integrations',
  //   exact: true,
  //   component: AccountIntegration
  // },
  // {
  //   path: '/cards',
  //   exact: true,
  //   component: Cards
  // },
  // {
  //   path: '/privacy_policy',
  //   exact: true,
  //   component: Notice
  // },
  // {
  //   path: '/waitlist',
  //   exact: true,
  //   component: WaitList
  // },
  // {
  //   path: '/terms_and_conditions',
  //   exact: true,
  //   component: Terms
  // },
  //   {
  //   path: '/business_continuity_disclosure',
  //   exact: true,
  //   component: BusinessContinuityDisclosure
  // },
  //     {
  //   path: '/add_waitlist',
  //   exact: true,
  //   component: AddWaitList
  // },
  // {
  //   path: '/email_verification',
  //   exact: true,
  //   component: ConfirmEmail
  // }
]

export default Pages
