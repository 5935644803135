import { FacebookAPI } from '../config/axios'

/** Class representing an organization. */
class Facebook {

  async postEvent () {
    let TOKEN = 'EAALwzqTIeiQBAGZB6mYOXM1HTyV7RiZCaGDxRm8LV819iOWMIllDeubxxq8ja2Xh4aLBv8ntUD4IobXcILEZAbSSHIfcflT9Ul7AjpofRmGa7xGSq1E3P8wHgbATcUmkyb6fxego9ZCPZBQEWC270ygnlg4IbyTqn8xyuQsKjz1otqg8oROHM'

    let PIXEL_ID = '353682186811694'

    try {
      const req = await FacebookAPI.post(`${PIXEL_ID}/events?access_token=${TOKEN}`, {
        "data": [
          {
             "event_name": "ViewContent",
             "event_time": 1652341125,
             "event_id": "event.id.123",
             "event_source_url": "http:\/\/jaspers-market.com",
             "user_data": {
                "client_ip_address": "1.2.3.4",
                "client_user_agent": "test user agent"
             }
          }
       ],
       "test_event_code": "TEST123"
      })

     
    } catch (error) {
      return { isSuccess: false }
    }
  }
}

export default new Facebook()
