import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../config/colors'


interface ISelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
	required?: boolean
	placeholder?: string
	width?: number
	options: Array<{ value: string | number; label: string }>
	hasLabel?: boolean
	label1?: string
	styledSelect?: IProps
}
interface IProps {
	width?: number
	marginBottom?: string | number
}
export default function Select({
	placeholder,
	width,
	options,
	label1,
	hasLabel,
	...rest
}: ISelect): any {
	return (
		<Group width={width}>
			{hasLabel === true && <label>{label1}</label>}
			<StyledSelect
				placeholder={placeholder}
				{...rest}
				marginBottom={rest?.styledSelect?.marginBottom}
			>
				{options.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</StyledSelect>
		</Group>
	)
}

const Group = styled.div<IProps>`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width ?? 100}%;
`

export const StyledSelect = styled.select<IProps>`
	padding: 12px 15px;
	box-sizing: border-box;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid ${COLORS.light};
	border-radius: 14px;
	opacity: 1;
	color: ${COLORS.black};
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-size: 12px;
	font-weight: 200;
	width: ${({ width }) => width ?? 100}%;
	margin-bottom: ${({ marginBottom }) => marginBottom ?? '25px'};
`
