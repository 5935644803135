import { API } from '../config/axios'
class Auth {

  async signIn (password: string) {
    try {
      if (password === 'gVMSjDu7%p@bv%#_') {
        return { isSuccess: true }
      }
      return { isSuccess: false }

    } catch (e) {
      return { isSuccess: false }
    }
  }
}

export default new Auth()
