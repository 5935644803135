import React from 'react';
import styled from 'styled-components';

interface IContainer {
  children?: React.ReactNode;
  width?: string;
}
function InputContainer({ children, width = '100%' }: IContainer) {
  return <TextInputContainer style={{ width }}>{children}</TextInputContainer>;
}

const TextInputContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
label {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 5px;
   /*  color: #fff; */
   /*  color: #ccc */
}
@media (max-width: 650px) {
    width: 100% !important;
}
`;

export default InputContainer;