import styled from 'styled-components'
import { device } from '../config/devices'

interface IProps {
  top?: number
  bottom?: number
}

export const Separator = styled.div<IProps>`
  margin-top: ${({ top }) => top || 2}%;
  margin-bottom: ${({ bottom }) => bottom || 2}%;

  @media ${device.laptopL} {
    margin-top: ${({ top }) => top || 5}%;
    margin-bottom: ${({ bottom }) => bottom || 5}%;
  }
`

export const MobileSeparator = styled.div<IProps>`
  margin-top: ${({ top }) => top || 2}%;
  margin-bottom: ${({ bottom }) => bottom || 2}%;

  @media ${device.laptopL} {
   display: none;
  }
`