import React from "react";
import styled from "styled-components";
import { blackLogo } from "../../assets";
import PrimaryBtn from "../../components/PrimaryBtn";
import { Separator } from "../../components/Separator";
import { Paragraph } from "../../components/Typography";
import { device } from "../../config/devices";
import {
  COMPANY_SIZE,
  delay,
  toastConfig,
  WAIT_LIST_STEPS,
} from "../../config/static";
import TextInput from "../../components/PrimaryInput";
import BackButton from "../../components/BackBtn";
import { BsEnvelopeOpen } from "react-icons/bs";
import InputContainer from "../../components/inputContainer";
import CountrySelect, { CountryType } from "../../components/CountrySelect";
import { toast } from "react-toastify";
import { API } from "../../config/axios";
import { Facebook } from "../../services";
import ReactGA from "react-ga4";
import { Spin } from "antd";
import expenseDashboard from "../../assets/images/waitList.jpg";
import { useNavigate } from "react-router-dom";
import Select from "../../components/select";

interface IUserDetails {
  emailAddress: string;
  firstName: string;
  lastName: string;
  companyName: string;
  title: string;
  location: string;
  supportedCountry: number;
  companySize: string;
}

function AddWaitList() {
  const navigate = useNavigate();
  const [section, setSection] = React.useState(1);
  const [convergedRegion, setConvergedRegion] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState<CountryType>({
    label: "",
    code: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedSize, setSelectedSize] = React.useState("1-50");
  const [newUser, setNewUser] = React.useState<IUserDetails>({
    emailAddress: "",
    firstName: "",
    lastName: "",
    companyName: "",
    title: "",
    location: "",
    supportedCountry: 0,
    companySize: selectedSize,
  });

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSaveUsers = async () => {
    try {
      setIsLoading(true);
      const isConvergeCountry =
        selectedCountry.label === "Kenya" ||
        selectedCountry.label === "South Africa";

      setConvergedRegion(isConvergeCountry);
      setNewUser({
        ...newUser,
        location: selectedCountry.label,
        supportedCountry: isConvergeCountry ? 1 : 0,
        companySize: selectedSize,
      });

      const usr: IUserDetails = {
          emailAddress: newUser.emailAddress,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          companyName: newUser.companyName,
          title: newUser.title,
          location: selectedCountry.label,
          supportedCountry: isConvergeCountry ? 1 : 0,
          companySize: selectedSize,
      }

      const disabled =
        usr.location === "" ||
        usr.companySize === "" ||
        usr.emailAddress === "" ||
        usr.firstName === "" ||
        usr.lastName === "" ||
        usr.companyName === "" ||
        usr.title === "";

      if (disabled) {
        setIsLoading(false);
        return toast.warn("Please fill all the fields", toastConfig);
      }

      const req = await API.post("SvoOrgWaitlist", usr);

      ReactGA.event("waitlisted");
      await Facebook.postEvent();

      setIsLoading(false);
      setSection(3);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setIsLoading(false);
        return toast.warn("You are already part of the waitlist", toastConfig);
      }
      setIsLoading(false);
      toast.error("Something went wrong", toastConfig);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LeftContainer></LeftContainer>
        <RightContainer>
          {section === 1 && (
            <RightWrapper>
              <Paragraph size={25} font={700} position="center">
                Get Started{" "}
              </Paragraph>

              <Separator />

              <div style={{ width: "80%" }}>
                <CountrySelect setSelectedCountry={setSelectedCountry} />
                <Separator />

                <Paragraph size={25} font={700} position="left">
                  Personal Information{" "}
                </Paragraph>
                <Paragraph size={13} font={300} position="left">
                  To get started, tell us about yourself.
                </Paragraph>

                <Separator />

                <InputContainerGroup>
                  <InputContainer width="48%">
                    <Paragraph font={200} size={12}>
                      First Name
                    </Paragraph>
                    <TextInput
                      placeholder="Enter your first name"
                      name="firstName"
                      onChange={handleInputValueChange}
                      value={newUser?.firstName as string}
                    />
                  </InputContainer>
                  <InputContainer width="48%">
                    <Paragraph font={200} size={12}>
                      Last Name
                    </Paragraph>
                    <TextInput
                      placeholder="Enter your last name"
                      name="lastName"
                      onChange={handleInputValueChange}
                      value={newUser?.lastName as string}
                    />
                  </InputContainer>
                </InputContainerGroup>

                <InputContainerGroup>
                  <InputContainer width="48%">
                    <Paragraph font={200} size={12}>
                      Company Name
                    </Paragraph>
                    <TextInput
                      placeholder="Enter your company name"
                      name="companyName"
                      onChange={handleInputValueChange}
                      value={newUser?.companyName as string}
                    />
                  </InputContainer>
                  <InputContainer width="48%">
                    <Paragraph font={200} size={12}>
                      Title
                    </Paragraph>
                    <TextInput
                      placeholder="Enter your title"
                      name="title"
                      onChange={handleInputValueChange}
                      value={newUser?.title as string}
                    />
                  </InputContainer>
                </InputContainerGroup>

                <InputContainerGroup>
                  <InputContainer width="100%">
                    <Paragraph font={200} size={12}>
                      Email Address
                    </Paragraph>
                    <TextInput
                      placeholder="Enter your email"
                      name="emailAddress"
                      onChange={handleInputValueChange}
                      value={newUser?.emailAddress as string}
                      type="email"
                    />
                  </InputContainer>
                </InputContainerGroup>

                <InputContainerGroup>
                  <InputContainer width="100%">
                    <Paragraph font={200} size={12}>
                      Company Size
                    </Paragraph>
                    <Select
                      name="companySize"
                      width={100}
                      value={selectedSize}
                      onChange={(event: any) =>
                        setSelectedSize(event.target.value)
                      }
                      options={COMPANY_SIZE}
                    />
                  </InputContainer>
                </InputContainerGroup>

                <Separator />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <BackButton
                    title="Back"
                    onClick={() => navigate("/")}
                    width="50%"
                  /> */}
                  <PrimaryBtn
                    title="Continue"
                    width="50%"
                    onClick={handleSaveUsers}
                    // disabled={Object.values(newUser).some(
                    //   (user) => user === ""
                    // )}
                  />
                </div>
              </div>
            </RightWrapper>
          )}

          {section === 3 && convergedRegion && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Paragraph size={25} font={700}>
                  Verify your email{" "}
                </Paragraph>
                <Paragraph size={13} font={300}>
                  Please follow the link we’ve sent to {newUser.emailAddress} to
                  verify your email address. Check your spam folder if you don't
                  see it.
                </Paragraph>

                <Separator />
              </div>
              <div>
                <BsEnvelopeOpen size={50} />
              </div>
            </div>
          )}

          {section === 3 && !convergedRegion && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Paragraph size={25} font={700}>
                  You've been added to the waitlist{" "}
                </Paragraph>
                <Paragraph size={13} font={300}>
                  We’re not in your region yet, but we’ll let you know when
                  we’re available there.
                </Paragraph>

                <Separator />
              </div>
              <div>
                <BsEnvelopeOpen size={50} />
              </div>
            </div>
          )}
        </RightContainer>
      </div>
    </Spin>
  );
}

const BlackLogo = styled.img`
  display: none;
  @media ${device.laptop} {
    display: block;
    height: auto;
    width: auto;
  }
`;

const InputContainerGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-direction: column;
    button {
      width: 100% !important;
      font-size: 12px;
    }
  }
`;
const LeftContainer = styled.div`
  display: none;
  @media ${device.laptop} {
    width: 40%;
    display: flex;
    height: 100vh;
    background-image: url(${expenseDashboard});
    background-position: left; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
`;
const RightContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media ${device.laptop} {
    padding: 0px 6.5em;
    width: 60%;
    justify-content: flex-start;
  }
`;
const RightWrapper = styled.div`
  width: 100%;
  @media ${device.laptop} {
    width: 100%;
  }
`;
export default AddWaitList;
