import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages from "./pages";

const RootRoutes = () => (
  <Routes>
    {Pages.map((route) => (
      <Route key={route.path} path={route.path} element={<route.component />} />
    ))}
  </Routes>
);
export default RootRoutes;
