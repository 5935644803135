import { AiOutlineAppstore } from 'react-icons/ai'
import { BsCardHeading, BsEnvelope  } from 'react-icons/bs'
import { ToastOptions } from 'react-toastify'
import { css } from 'styled-components'
import {IoDocumentsOutline} from 'react-icons/io5'

export const toastConfig = ({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    marginTop: '50px',
    background: '#fffff'
  })
} as unknown) as ToastOptions


export const delay = (ms: any )=> new Promise(res => setTimeout(res, ms))

export const WAIT_LIST_STEPS = [
  {
    title: 'Confirm your email address',
    icon: <BsEnvelope size={20} color='#4c8190'/>,
    background: '#e3eef0'
  }
   //,
  // {
  //   title: 'Download the app',
  //   icon: <AiOutlineAppstore size={20} color='#6ab7a5'/>,
  //   background: '#e4f1ef'
  // },
  // {
  //   title: 'Verify your identity',
  //   icon: <IoDocumentsOutline size={20} color='#e4d0ae'/>,
  //   background: '#f1e9da'
  // },
  // {
  //   title: 'Get your Sava debit card',
  //   icon: <BsCardHeading size={20} color='#cb7c7a'/>,
  //   background: '#f2e3e4'
  // }
  
]

export const COMPANY_SIZE = [
	{
		label: 'Small (1-50 employees)',
		value: '1-50',
	},
	{
		label: 'Medium Medium (51-200 employees)',
		value: '51-200',
	},
	{
		label: 'Large (201+ employees)',
		value: '201+',
	}
]